import React, { useEffect, useState } from "react";
import { Transition } from "react-transition-group";

export default function ChatWindow(props) {

    const duration = 2500;

    const [clsname, setClsname] = useState(false);


    const [isOpen, toggleIsOpen] = useState(false);
    const onOpenChatClick = () => toggleIsOpen(true);
    const onCloseChatClick = () => toggleIsOpen(false);

    const closeChat=()=>{
       

        if (isOpen) {
            toggleIsOpen(false)
            setClsname("chatwindow-container hide")

        }
        else {
            toggleIsOpen(true)
            setClsname("chatwindow-container show")

        }
    }

    useEffect(() => {
        let mounted = true;
        debugger;
        if (isOpen) {
            setClsname("chatwindow-container show")
        }
        else {
            setClsname("chatwindow-container hide")
        }

        return () => mounted = false;
    }, [isOpen]);

    return (
        <div className={clsname}>
            <span className="close-chat" onClick={closeChat}>
                <i className="fa fa-comment fa-3x" style={{color:"white"}}></i>
            </span>
            <iframe src="http://timologic-openai-agent-env.eba-mcumjxhi.eu-central-1.elasticbeanstalk.com/" style={{ width: '100%', height: '100%' }}></iframe>
        </div>
    );
}