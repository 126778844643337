import React,{useState} from "react";
import { useHistory } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import { Switch, Route } from "react-router-dom";
import PrivateRoute from './privateRoute'
import MainNavBar from "./navbar";

import Login from "../pages/login";
import Register from "../pages/register";
import ForgotPassword from "../pages/forgot_password";
import ResetPassword from "../pages/reset_password";

import Dashboard from "../pages/dashboard";
import Profile from "../pages/profile";

import SaleSearch from "../pages/sale-search";
import SaleAdd from "../pages/sale-add";
import SaleEdit from "../pages/sale-edit";
import CustomerSearch from "../pages/customer-search";
import CustomerAdd from "../pages/customer-add";
import UserSearch from "../pages/admin/user-search";
import UserAdd from "../pages/admin/user-add";
import ProductSearch from "../pages/product-search";
import ProductAdd from "../pages/product-add";
import CompanyAdd from "../pages/company-add";
import CompanySearch from "../pages/company-search";
import InvoiceTypeAdd from "../pages/invoicetype-add";
import InvoiceTypeSearch from "../pages/invoicetype-search";
import InvoiceView from "../pages/viewinvoice";
import AccountantAssignment from "../pages/accountant-assignment";
import TemplateDesigner from "../pages/template-designer";
import TemplateSearch from "../pages/template-search";

import AadeSaleSearch from "../pages/aade-sales-search";
import AadeSaleSearchByMark from "../pages/aade-sale-search-bymark";
import CompanyIncomes from "../pages/company-incomes";

import PreSaleSearch from "../pages/pre-sale-search";


import SaleGroupedSearch from "../pages/sale-grouped-search";

import BusinessCard from "../pages/company-business-card";

import Deductions from "../pages/company-deduction";

import ProductCategoryAdd from "../pages/product-category-add";
import ProductCategorySearch from "../pages/product-category-search";
import vivaRedirectHandler from '../pages/viva-redirect';
import NotFound from '../pages/notfound';
import Swagger from "../pages/swagger";


import TemplateComponent from "./templates/template.component"
import Paymemnt from "./payment.component.js";
import ChoosePackage from "./choosepackage";
import StartYourSubscription from "./startSubscription.js";
import OAuth2RedirectHandler from '../services/OAuth2RedirectHandler';

import StripePayment from "../pages/stripe-payment";
import PaymentStatus from "../pages/stripe-payment-complete";
import {version} from '../../package.json';

import ChatWindow from "./chat.component.js";


import Error from "./error";
import timologicFooter from "../img/timologic_login.png"
const MainLayout = (props) => {


    const { t } = useTranslation();
    const DefaultContainer = () => (
        <div className="innerContainer">
            <MainNavBar user={localStorage.getItem("user")} />
            <main className="flex-shrink-0 innerContainerMain">
                <div className="container-fluid mainContainer" onClick={function(){
                    var isShown=document.getElementById("basic-navbar-nav").classList.contains("show");
                    if(isShown){
                        document.getElementById("main-nav-toogler").click();
                    }
                  
                }}>
                    <Switch>

                        <PrivateRoute exact path={["/", "/home"]} component={Dashboard} />
                        <PrivateRoute exact path="/profile" component={Profile} />

                        <PrivateRoute exact path="/sale/:id" component={SaleEdit} />
                        <PrivateRoute exact path="/sale" component={SaleAdd} />
                        <PrivateRoute exact path="/sales" component={SaleSearch} />
                        <PrivateRoute exact path="/sales/grouped" component={SaleGroupedSearch} />
                        <PrivateRoute exact path="/presales" component={PreSaleSearch} />

                        <PrivateRoute exact path="/incomes" component={CompanyIncomes} />
                        
                        
                        {/* ------------------------------------------------ */}
                        <PrivateRoute exact path="/aade/sales" component={AadeSaleSearch} />
                        <PrivateRoute exact path="/aade/sale" component={AadeSaleSearchByMark} />


                        {/* ------------------------------------------------ */}
                        <PrivateRoute exact path="/customer" component={CustomerAdd} />
                        <PrivateRoute exact path="/customer/:id" component={CustomerAdd} />
                        <PrivateRoute exact path="/customers" component={CustomerSearch} />

                        {/* ------------------------------------------------ */}
                        <PrivateRoute exact path="/product" component={ProductAdd} />
                        <PrivateRoute exact path="/product/:id" component={ProductAdd} />
                        <PrivateRoute exact path="/products" component={ProductSearch} />

                        {/* ------------------------------------------------ */}
                        <PrivateRoute exact path="/user" component={UserAdd} />
                        <PrivateRoute exact path="/user/:id" component={UserAdd} />
                        <PrivateRoute exact path="/users" component={UserSearch} />

                        {/* ------------------------------------------------ */}
                        {/* <PrivateRoute exact path="/usercompanies" component={UserCompanySearch} /> */}
                        <PrivateRoute exact path="/usercompany" component={CompanyAdd} />
                        <PrivateRoute exact path="/usercompany/:id" component={CompanyAdd} />
                        <PrivateRoute exact path="/usercompanies" component={CompanySearch} />

                        {/* ------------------------------------------------ */}
                        <PrivateRoute exact path="/invoiceType" component={InvoiceTypeAdd} />
                        <PrivateRoute exact path="/invoiceType/:id" component={InvoiceTypeAdd} />
                        <PrivateRoute exact path="/invoiceTypes" component={InvoiceTypeSearch} />


                        {/* ------------------------------------------------ */}
                        <PrivateRoute exact path="/productCategory" component={ProductCategoryAdd} />
                        <PrivateRoute exact path="/productCategory/:id" component={ProductCategoryAdd} />
                        <PrivateRoute exact path="/productCategories" component={ProductCategorySearch} />


                        
                        <PrivateRoute exact path="/error" component={Error} />
                        <PrivateRoute exact sale={{}} path="/payment" component={Paymemnt} />
                        <PrivateRoute exact sale={{}} path="/accountantAssignment" component={AccountantAssignment} />

                        <PrivateRoute exact path="/designer/:id" component={TemplateDesigner} />
                        <PrivateRoute exact path="/templates" component={TemplateSearch} />

                        <PrivateRoute exact path="/deductions" component={Deductions} />
                    


                        
                        <PrivateRoute component={NotFound}></PrivateRoute>
                        {/* <Redirect to="/home" /> */}
                    </Switch>
                    <div className="footer navbar-fixed-bottom">
                    </div>
                </div>
            </main>
            <footer className="footer mt-auto py-3 main-footer">
                <div className="container">
                    <span className="text-muted"><img className="footerImage" src={timologicFooter}></img><small> {version}</small>
                            {/* <Tippy trigger="click" content={<span className="tooltip-skin">{t("technical_support_info")}</span>}>
                                <i className="fa fa-x2 fa-info-circle infoicon phonefootericon"></i>
                            </Tippy> */}
                    </span>
                    {/* <p  className="footermess"> <span>{t("footermess")}</span></p> */}
                    <ChatWindow ></ChatWindow>
                </div>
            </footer>
        </div>
    )
    return (
        <Switch>
            <Route path="/login"
                render={(props) => <Login {...props} />}></Route>
            <Route exact path="/register" component={Register} />
            <Route exact path="/forgot" component={ForgotPassword} />
            <Route exact path="/reset/:token" component={ResetPassword} />
            <Route exact path="/businessCard/:taxpayerId/:branchId" component={BusinessCard} />

            
            <Route exact path="/mydata/:invoiceUid" component={InvoiceView}></Route>

            <Route path="/oauth2/redirect" component={OAuth2RedirectHandler}></Route>
            <Route path="/viva/redirect" component={vivaRedirectHandler}></Route>
            <Route exact path="/template"
                render={(props) => <TemplateComponent {...props} />}></Route>

            {/* <Route exact sale={{}} path="/payment" component={Paymemnt} /> */}

            <Route exact path="/checkout" component={StripePayment} />
            <Route exact path="/checkout/complete" component={PaymentStatus} />

            <Route exact path="/swagger" component={Swagger} />

            <Route exact sale={{}} path="/choosepackage" component={ChoosePackage} />
            <Route exact sale={{}} path="/startSubscription" component={StartYourSubscription} />
            <Route
                render={(props) => <DefaultContainer {...props} />}></Route>




        </Switch>
    );
}
export default MainLayout

